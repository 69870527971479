<template>
  <div>
    <a-card title="库存明细">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-input-search v-model="searchForm.search" placeholder="产品编号, 名称" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-input-search v-model="searchForm.number" placeholder="批次编号" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button icon="download" @click="exportData" style="width: 100%">导出</a-button>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            :scroll="{ y: 480 }"
            @change="changeTable"
          />
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { batchInventoryList, batchInventoryExport } from "@/api/report";
import { exportExcel } from "@/utils/excel";

export default {
  data() {
    return {
      searchForm: { search: "", page: 1, page_size: 48, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 48 },
      dataLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          customRender: (_value, _item, index) => index + 1,
        },
        {
          title: "仓库",
          dataIndex: "warehouse_name",
          customRender: (_value, item) => item.warehouse_item.name,
        },
        {
          title: "产品编号",
          dataIndex: "material_number",
          customRender: (_value, item) => item.material_item.number,
        },
        {
          title: "产品名称",
          dataIndex: "material_name",
          customRender: (_value, item) => item.material_item.name,
        },
        {
          title: "批号",
          dataIndex: "number",
        },
        {
          title: "库存数量",
          dataIndex: "remain_quantity",
          sorter: true,
        },
        {
          title: "单位",
          dataIndex: "unit",
          customRender: (_value, item) => item.material_item.unit,
        },
        {
          title: "生产日期",
          dataIndex: "production_date",
          sorter: true,
        },
        {
          title: "到期日期",
          dataIndex: "expiration_date",
          sorter: true,
        },
      ],
      dataItems: [],
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      this.dataLoading = true;
      batchInventoryList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    exportData() {
      batchInventoryExport(this.searchForm).then((resp) => {
        exportExcel(resp, "库存明细");
      });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
